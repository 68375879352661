/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
import qs from 'qs'
import Cookies from 'js-cookie'
import { pick, values, concat, uniq } from 'lodash'

export const isBlank = (obj) => {
  return !(obj && obj.length && obj.length > 0)
}

export const getQuery = () => {
  return qs.parse(document.location.search.split('?')[1])
}

export const saveTokenFromQuery = (token) => {
  Cookies.set('token', token, { expires: 7 })
  return token
}

export const assignQuery = (newQuery) => {
  const query = qs.parse(document.location.search.split('?')[1])
  return qs.stringify(Object.assign(query, newQuery))
}

export const getToken = () => {
  let { token } = getQuery()
  if (!isBlank(token)) {
    return saveTokenFromQuery(token)
  }
  token = Cookies.get('token')
  if (!isBlank(token)) {
    return token
  }
  return null
}

export const getAppId = () => {
  let appId = null
  const reg = new RegExp(/\/apps\/(\d+)\/.*/)
  const res = window.location.href.match(reg)
  appId = (res && res.length > 0 && res[1]) || null
  if (appId == null) {
    const query = qs.parse(document.location.search.replace('?', ''))
    return query.appId
  }
  return appId
}

export const filterTreeByTitle = (tree, valueAry) => {
  return tree.filter(function iter(node) {
    let temp = []
    if (node.title && node.title.indexOf(valueAry) !== -1) {
      return true
    }
    if (!Array.isArray(node.children) || node.children.length === 0) {
      return false
    }
    temp = node.children.filter(iter)
    if (temp.length) {
      node.children = temp
      return true
    }
    return false
  })
}

export const filterSfTreeByTitle = (tree, valueAry) => {
  return tree.filter(function iter(node) {
    let temp = []
    if (node.name.indexOf(valueAry) !== -1) {
      return true
    }
    if (
      !Array.isArray(node.organizationVOs) ||
      node.organizationVOs.length === 0
    ) {
      return false
    }
    temp = node.organizationVOs.filter(iter)
    if (temp.length) {
      node.organizationVOs = temp
      return true
    }
    return false
  })
}

export const parseQueryString = () => {
  const obj = {}
  let keyValue = []
  let key = ''
  let value = ''
  const paraString = window.location.search.slice(1).split('&')
  // eslint-disable-next-line guard-for-in
  for (const index in paraString) {
    keyValue = paraString[index].split('=')
    // eslint-disable-next-line prefer-destructuring
    key = keyValue[0]
    // eslint-disable-next-line prefer-destructuring
    value = keyValue[1]
    obj[key] = value
  }
  return obj
}

// 资源-获取当前所有的组织id
export const dealOrgIdsRes = (data, defs) => {
  const slugs = defs
    .filter((v) => v.type === 'org' || v.type === 'orgMultiple')
    .map((v) => {
      return v.slug
    })
  let orgIds = []

  const orgArr = data.map((v) => {
    const d = values(pick(v, slugs))
    return concat([], ...d)
  })
  orgArr.forEach((d) => {
    orgIds = orgIds.concat(d)
  })
  return uniq(orgIds)
}

// 资源-获取当前所有的用户id
export const dealUserIdsRes = (data, defs) => {
  const slugs = defs
    .filter((v) => v.type === 'user' || v.type === 'userMultiple')
    .map((v) => {
      return v.slug
    })
  let userIds = []

  const userArr = data.map((v) => {
    const d = values(pick(v, slugs))
    return concat([], ...d)
  })
  userArr.forEach((d) => {
    userIds = userIds.concat(d)
  })
  return uniq(userIds)
}

export const colortopArr = [
  'RGB(45,127,249)',
  'RGB(24,191,255)',
  'RGB(32,217,210)',
  'RGB(255,8,194)',
  'RGB(248,43,96)',
  'RGB(255,111,44)',
  'RGB(252,180,0)',
  'RGB(32,201,51)',
  'RGB(139,70,255)',
  'RGB(102,102,102)',
]
export const colorbotArr = [
  'RGB(207,223,255)',
  'RGB(208,240,253)',
  'RGB(194,245,233)',
  'RGB(255,218,246)',
  'RGB(255,220,229)',
  'RGB(254,226,213)',
  'RGB(255,234,182)',
  'RGB(209,247,196)',
  'RGB(237,226,254)',
  'RGB(238,238,238)',
]

export const getUUID = () => {
  const s = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] && 0x3) || 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = '-'
  s[13] = '-'
  s[18] = '-'
  s[23] = '-'

  const id = s.join('')
  return id
}
