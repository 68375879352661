import React, { lazy, Suspense } from 'react'
import qs from 'qs'
import { getQuery, saveTokenFromQuery } from '@sw/utils/lib/index'
import Loading from '@components/loading/normal'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // useHistory,
} from 'react-router-dom'
import SWRConfig from '@utils/swr'
// import { dygcDomain } from '@utils/constants'
import '@styles/tailwind.css'
import '@styles/tailwind.form.css'
import '@styles/scrollbar.css'
import '@styles/font.css'
import 'reactjs-popup/dist/index.css'
import 'react-tabs/style/react-tabs.css'
import 'rc-tree/assets/index.css'
import 'rc-drawer/assets/index.css'
import 'react-virtualized/styles.css' // only needs to be imported once
// 日期选择器样式
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css'

function App() {
  const query = getQuery()
  const { token } = getQuery()
  if (token) {
    const list = {}
    Object.keys(query).forEach((x) => {
      if (x !== 'token') {
        list[x] = query[x]
      }
    })
    const { pathname } = window.location
    saveTokenFromQuery(token)
    window.location.href = `${pathname}${Object.keys(query).length > 1 ? '?' : ''}${qs.stringify(list)}`
  }
  return (
    <div className="App font-hei">
      {/* {!token && '未登录'} */}
      <Suspense fallback={<Loading />}>
        <SWRConfig>
          <Router>
            <Switch>
              <Route
                path="/login"
                component={React.lazy(() => import('@pages-sw/login'))}
              />
              <Route
                path="/apps/:appId"
                component={React.lazy(() => import('@pages-app/layouts/main'))}
              />
              <Route
                path="/tbeditor"
                exact
                component={lazy(() => import('@pages-editor/layouts/main'))}
              />
              <Route
                path="/dashboard"
                exact
                component={lazy(() => import('@pages-sw/index'))}
              />
              <Route
                path="/maps/:appId"
                exact
                component={lazy(() => import('@pages-map/index'))}
              />
              <Route
                path="/todo"
                exact
                component={lazy(() => import('@sw/components/src/navs/sidenav/todo'))}
              />
              {/* <Route
                path="/tbflows"
                exact
                component={React.lazy(() => import('@pages-flow/layouts/index'))}
              /> */}
              {/* <Route
                path="/users"
                component={React.lazy(() => import('@pages-users/users'))}
              />
              <Route
                path="/invite"
                component={React.lazy(() =>
                  import('@pages-users/users/invite')
                )}
              />
              <Route
                path="/roles"
                component={React.lazy(() => import('@pages-users/users/role'))}
              /> */}
              <Route
                path="/ditto"
                component={React.lazy(() => import('@pages-ditto/index'))}
              />
              <Route
                path="/floweditor"
                component={React.lazy(() => import('@pages-flow/layouts/main'))}
              />
            </Switch>
          </Router>
        </SWRConfig>
      </Suspense>
    </div>
  )
}

export default App
