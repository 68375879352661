import PROJECTS from '@sw/apis/lib/utils/projects'
import { getToken } from '../index'

// 项目
const RAP = process.env.REACT_APP_PROJECT
// 环境(dev,test,prod)
const DEP_ENV = process.env.REACT_APP_DEP
console.log(DEP_ENV, '当前环境')

const isEnvExist = ['dev', 'test', 'prod', 'zw'].includes(DEP_ENV)
// 类似/apps/$appId/res/95?token=$token'形式的路径参数值替换
export const transUrl = (url, appId) => {
  if (!url) return null
  if (appId) {
    return url.replace('$appId', appId).replace('$token', getToken())
  }
  return url.replace('$token', getToken())
}

// 判断是否是当前项目，是否需要切换项目
const isReverseNav = (nav) => {
  if (RAP && nav.project) {
    // 切换项目
    return RAP !== nav.project
  }
  return false
}

export const reverseNav = (nav) => {
  // 先判断环境
  if (!isEnvExist) {
    return nav.url
  }

  const env = PROJECTS[DEP_ENV]
  const project = env[nav.project]
  if (!project) {
    return nav.url
  }

  if (DEP_ENV === 'dev' && project.port) {
    return `//${document.location.hostname}:${project.port}${nav.url}`
  }
  if ((DEP_ENV === 'test' || DEP_ENV === 'prod') && project.port) {
    return `//${project.domain}${nav.url}`
  }
  if (DEP_ENV === 'zw' && project.port) {
    return `//${project.domain}${nav.url}`
  }
  return nav.url
}

// 路径跳转方式
/* eslint-disable no-unused-expressions */
export const UrlJumpMode = (nav = {}, appId) => {
  const url = transUrl(nav.url, appId)
  const isReverse = isReverseNav(nav)
  console.log(`${nav.navType} to:`, url, process.env)
  if (nav.navType === 'push') {
    if (isReverse) {
      return { navType: 'href', url: transUrl(reverseNav(nav), appId) }
    }
    return { navType: 'push', url }
  }
  if (nav.navType === 'replace') {
    if (isReverse) {
      return { navType: 'href', url: transUrl(reverseNav(nav), appId) }
    }
    return { navType: 'replace', url }
  }
  if (nav.navType === 'refresh') {
    if (isReverse) {
      return { navType: 'push', url }
    }
    return { navType: 'href', url }
  }
  if (nav.navType === 'open') {
    if (nav.project && isReverse) {
      return { navType: 'open', url: transUrl(reverseNav(nav), appId) }
    }
    return { navType: 'open', url }
  }
  return {}
}
