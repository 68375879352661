/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */

import qs from 'qs'
import Cookies from 'js-cookie'
import { pick, values, concat, uniq, flatten } from 'lodash'
import { UrlJumpMode } from '@sw/utils/lib/url-deal/nav-jump'

export const isBlank = (obj) => {
  return !(obj && obj.length && obj.length > 0)
}

export const getQuery = () => {
  return qs.parse(document.location.search.split('?')[1])
}

export const saveTokenFromQuery = (token) => {
  // const domain = 'localhost'
  Cookies.set('token', token)
  // Cookies.set('token', token, { domain: `10.161.106.240` })
  // const arr = [141, 113, 67, 243, 198, 115, 170, 139, 240, 105]
  // arr.forEach((num) => {
  //   Cookies.set('token', token, { domain: `10.161.106.240` })
  // })
  // const arr2 = [198]
  // arr2.forEach((num) => {
  //   Cookies.set('token', token, { domain: `210.12.197.${num}` })
  // })
  return token
}

export const assignQuery = (newQuery) => {
  const query = qs.parse(document.location.search.split('?')[1])
  return qs.stringify(Object.assign(query, newQuery))
}

export const getToken = () => {
  let { token } = getQuery()
  if (!isBlank(token)) {
    return saveTokenFromQuery(token)
  }
  token = Cookies.get('token')
  if (!isBlank(token)) {
    return token
  }
  return null
}

export const removeToken = () => {
  Cookies.remove('token')
}
export const getAppId = () => {
  let appId = null
  const reg = new RegExp(/\/apps\/(\d+)\/.*/)
  const res = window.location.href.match(reg)
  appId = (res && res.length > 0 && res[1]) || null
  if (appId == null) {
    const query = qs.parse(document.location.search.replace('?', ''))
    return query.appId
  }
  return appId
}

export const getResId = () => {
  let resId = null
  const reg = new RegExp(/\/res\/(\d+)\/.*/)
  const res = window.location.href.match(reg)
  resId = (res && res.length > 0 && res[1]) || null
  if (resId == null) {
    const query = qs.parse(document.location.search.replace('?', ''))
    return query.resId
  }
  return resId
}

export const filterTreeByTitle = (tree, valueAry) => {
  return tree.filter(function iter(node) {
    let temp = []
    if (node.title && node.title.indexOf(valueAry) !== -1) {
      return true
    }
    if (!Array.isArray(node.children) || node.children.length === 0) {
      return false
    }
    temp = node.children.filter(iter)
    if (temp.length) {
      node.children = temp
      return true
    }
    return false
  })
}

export const filterSfTreeByTitle = (tree, valueAry) => {
  return tree.filter(function iter(node) {
    let temp = []
    if (node.name.indexOf(valueAry) !== -1) {
      return true
    }
    if (
      !Array.isArray(node.organizationVOs) ||
      node.organizationVOs.length === 0
    ) {
      return false
    }
    temp = node.organizationVOs.filter(iter)
    if (temp.length) {
      node.organizationVOs = temp
      return true
    }
    return false
  })
}

export const parseQueryString = () => {
  const obj = {}
  let keyValue = []
  let key = ''
  let value = ''
  const paraString = window.location.search.slice(1).split('&')
  // eslint-disable-next-line guard-for-in
  for (const index in paraString) {
    keyValue = paraString[index].split('=')
    // eslint-disable-next-line prefer-destructuring
    key = keyValue[0]
    // eslint-disable-next-line prefer-destructuring
    value = keyValue[1]
    obj[key] = value
  }
  return obj
}

// 资源-获取当前所有的组织id
export const dealOrgIdsRes = (data, defs) => {
  const slugs = defs
    .filter((v) => v.type === 'org' || v.type === 'orgMultiple')
    .map((v) => {
      return v.slug
    })
  let orgIds = []

  const orgArr = data.map((v) => {
    const d = values(pick(v, slugs))
    return concat([], ...d).filter((i) => i)
  })
  orgArr.forEach((d) => {
    orgIds = orgIds.concat(d)
  })
  return uniq(orgIds)
}

// 资源-获取当前所有的用户id
export const dealUserIdsRes = (data, defs) => {
  const slugs = defs
    .filter((v) => v.type === 'user' || v.type === 'userMultiple')
    .map((v) => {
      return v.slug
    })
  let userIds = []

  const userArr = data.map((v) => {
    const d = values(pick(v, slugs))
    return concat([], ...d)
  })
  userArr.forEach((d) => {
    userIds = userIds.concat(d)
  })
  return uniq(userIds)
}

export const dealStandardsRes = (data, defs) => {
  const slugs = defs
    .filter((v) => v.type === 'standard')
    .map((v) => {
      return v.slug
    })
  let standardIds = []

  const standardArr = data.map((v) => {
    const d = values(pick(v, slugs))
    return concat([], ...d)
  })
  standardArr.forEach((d) => {
    standardIds = standardIds.concat(Array.isArray(d) ? flatten(d) : d)
  })
  return uniq(standardIds)
}

export const colortopArr = [
  'RGB(45,127,249)',
  'RGB(24,191,255)',
  'RGB(32,217,210)',
  'RGB(255,8,194)',
  'RGB(248,43,96)',
  'RGB(255,111,44)',
  'RGB(252,180,0)',
  'RGB(32,201,51)',
  'RGB(139,70,255)',
  'RGB(102,102,102)',
]
export const colorbotArr = [
  'RGB(207,223,255)',
  'RGB(208,240,253)',
  'RGB(194,245,233)',
  'RGB(255,218,246)',
  'RGB(255,220,229)',
  'RGB(254,226,213)',
  'RGB(255,234,182)',
  'RGB(209,247,196)',
  'RGB(237,226,254)',
  'RGB(238,238,238)',
]

export const getUUID = () => {
  const s = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] && 0x3) || 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = '-'
  s[13] = '-'
  s[18] = '-'
  s[23] = '-'

  const id = s.join('')
  return id
}
// List 转 url传参
export function getParam(obj) {
  if (!obj) return ''
  const arr = Object.keys(obj)
  let str = '?'
  arr.forEach((i) => {
    str += `${i}=${obj[i]}&`
  })
  str = str.substr(0, str.length - 1)

  return str
}

export const routeTo = (url, appId, history) => {
  if (typeof url === 'object') {
    const obj = UrlJumpMode(url, appId)
    const { navType } = obj
    if (obj.url) {
      if (navType === 'push' && history) {
        history.push(obj.url)
      } else if (navType === 'replace' && history) {
        history.replace(obj.url)
      } else if (navType === 'href') {
        window.location.href = obj.url
      } else if (navType === 'open') {
        window.open(obj.url)
      } else {
        window.location.href = obj.url
      }
    }
  } else {
    window.location.href = url
  }
}
export const shenRoleAdress = {
  市容环境审计员: '/apps/957/res/95?tbId=578&tpl=journal&token=',
  大气防治审计员: '/apps/957/res/95?tbId=572&tpl=journal&token=',
  垃圾分类审计员: '/apps/957/res/95?tbId=604&tpl=journal&token=',
  综合交通审计员: '/apps/957/res/95?tbId=605&tpl=journal&token=',
  卫士防疫审计员: '/apps/957/res/95?tbId=606&tpl=journal&token=',
  领航西城审计员: '/apps/957/res/95?tbId=607&tpl=journal&token=',
  单车大屏审计员: '/apps/957/res/95?tbId=608&tpl=journal&token=',
  扬尘系统审计员: '/apps/957/res/95?tbId=613&tpl=journal&token=',
  交通全景审计员: '/apps/957/res/95?tbId=617&tpl=journal&token=',
  停车大屏审计员: '/apps/957/res/95?tbId=618&tpl=journal&token=',
}
// 特殊角色进入指定页面
export const roleArr = [
  '区领导',
  '核酸监测录入',
  '街道',
  '组织部用户',
  '科博会演示',
  '街道疫情防控专员',
  '垃圾分类街道',
  '区街驾驶舱街道',
  '人民医院大屏',
]
// 处理西城项目 内网、互联网
export const getAdress = (role) => {
  console.log(role, 'rolerole角色')
  const { hostname } = window.location
  const { REACT_APP_DEP } = process.env
  const isTest = ['test', 'dev', 'zw'].includes(REACT_APP_DEP)

  const hospital = {
    pro: {
      in: 'http://172.25.79.160:8001/index.html',
      out: 'http://172.25.79.160:8001/index.html',
    },
    test: {
      in: 'http://172.25.79.160:8001/index.html',
      out: 'http://172.25.79.160:8001/index.html',
    },
  }
  // 科博会入口
  const kbh = {
    pro: {
      in: 'http://10.161.106.93:8834/dev/#/Lhxc',
      out: '',
    },
    test: {
      in: null,
      out: null,
    },
  }
  // 防疫卫士
  const guard = {
    pro: {
      in: 'http://10.161.106.115:8002/#/',
      out: 'http://210.12.197.198:8024/#/',
    },
    test: {
      in: null,
      out: null,
    },
  }
  // 领域西城
  const area = {
    pro: {
      in: 'http://10.161.106.93:8834/#/Lhxc',
      out: 'http://210.12.197.198:8000/#/Lhxc',
    },
    test: {
      in: 'http://10.161.106.115:8009/#/Lhxc',
      out: 'http://210.12.197.198:8025/#/Lhxc',
    },
  }
  // 领域西城后台
  const areaAdmin = {
    pro: {
      in: 'http://10.161.106.93:2701/tokenlogin',
      out: 'http://210.12.197.198:8001/tokenlogin',
    },
    test: {
      in: 'http://10.161.106.115:8015/tokenlogin',
      out: 'http://210.12.197.198:8026/tokenlogin',
    },
  }
  // 垃圾大屏
  const garbage = {
    pro: {
      in: 'http://10.161.106.243:8038/xicheng/index.html#/app/garbage?projectId=xicheng',
      out: '',
    },
    test: {
      in: 'http://10.161.106.243:8038/xicheng/index.html#/app/garbage?projectId=xicheng',
      out: null,
    },
  }
  // 区街两级驾驶舱大屏
  const quStreet = {
    pro: {
      in: 'http://10.161.106.115:8010/#/',
      out: '',
    },
    test: {
      in: '',
      out: null,
    },
  }
  const ipConfig = {
    区领导: area,
    核酸监测录入: areaAdmin,
    区领导联络员: areaAdmin,
    组织部用户: guard,
    街道: guard,
    科博会演示: kbh,
    街道疫情防控专员: guard,
    垃圾分类街道: garbage,
    区街驾驶舱街道: quStreet,
    人民医院大屏: hospital,
  }

  return ipConfig[role][isTest ? 'test' : 'pro'][
    hostname.indexOf('10.161.106') !== -1 ? 'in' : 'out'
  ]
}

export * from './js-extend/index'
