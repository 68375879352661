import React, { useState, useEffect } from 'react'
// import { Spin } from 'antd'
import './style.css'

const Loading = ({ title, delay = 250 }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
    }, delay)
    return () => {
      clearTimeout(timeout)
    }
  }, [delay])

  return show ? (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <span className="ant-spin-dot ant-spin-dot-spin">
        <i className="left-0 top-0" />
        <i className="right-0 top-0" style={{ animationDelay: '0.4s' }} />
        <i className="right-0 bottom-0" style={{ animationDelay: '0.8s' }} />
        <i className="left-0 bottom-0" style={{ animationDelay: '1.2s' }} />
      </span>
      <span className="mt-5" style={{ color: '#1890ff' }}>
        {title}
      </span>
    </div>
  ) : null
}

export default Loading
