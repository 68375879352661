import axios from 'axios'
// import qs from 'qs'
import { getToken } from '@utils'
// import { dygcDomain } from '@utils/constants'

const token = getToken()
if (token) {
  axios.defaults.headers.token = token
}

axios.interceptors.request.use(
  (config) => {
    // console.log(token)
    return {
      ...config,
      params: {
        ...config.params,
        token,
      },
    }
  },
  (err) => {
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  (config) => {
    return config
  },
  (err) => {
    if (err.response.status === 401) {
      console.log('token失效')
      // window.location.href = `${dygcDomain}/login?url=${window.location.href}`
    }
    return Promise.reject(err)
  }
)

export default axios
