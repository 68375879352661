const { hostname } = window.location
export default {
  dev: {
    web: {
      port: '4000',
      domain: 'localhost',
    },
    nest: {
      port: '4004',
      domain: 'localhost',
    },
  },
  zw: {
    web: {
      port: hostname.indexOf("10.161.106") !== -1 ? '4000' : '8029',
      domain: hostname.indexOf("10.161.106") !== -1 ? '10.161.106.105' : '210.12.197.198:8029',
    },
    nest: {
      port: hostname.indexOf("10.161.106") !== -1 ? '4004' : '8008',
      domain: hostname.indexOf("10.161.106") !== -1 ? '10.161.106.240' : '210.12.197.198:8008',
    },
  },
  test: {
    web: {
      port: '4000',
      domain: 't2.jianchaba.com',
    },
    nest: {
      port: '4004',
      domain: 't7.survey.work',
    },
  },
  prod: {
    web: {
      port: hostname.indexOf("10.161.106") !== -1 ? '80' : '8004',
      domain: hostname.indexOf("10.161.106") !== -1 ? '10.161.106.139:80' : '210.12.197.198:8004',
      // domain: '39.107.89.56:8002',
    },
    nest: {
      port: hostname.indexOf("10.161.106") !== -1 ? '80' : '8006',
      domain: hostname.indexOf("10.161.106") !== -1 ? '10.161.106.113:80' : '210.12.197.198:8006',
      // domain: '39.107.89.56:8013',
    },
  },
}
