import React from 'react'
import { SWRConfig } from 'swr'
// import { dygcDomain } from '@utils/constants'
import axios from './axios'

const fetcher = (url) => {
  return axios.get(url).then((res) => res)
}
const SwrConfig = ({ children }) => {
  return (
    <SWRConfig
      value={{
        fetcher,
        revalidateOnFocus: false,
        dedupingInterval: 30000,
        onError: (error) => {
          if (error.status === 401) {
            // window.location.href = `${dygcDomain}/login?url=${window.location.href}`
          }
        },
      }}
    >
      {children}
    </SWRConfig>
  )
}

export default SwrConfig
